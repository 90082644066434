
/**
 * @roxi/routify 2.18.5
 * File generated Fri Sep 20 2024 14:03:20 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.5"
export const __timestamp = "2024-09-20T14:03:20.988Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => import('../src/pages/_fallback.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/admin/index",
          "id": "_admin_index",
          "component": () => import('../src/pages/admin/index.svelte').then(m => m.default)
        }
      ],
      "path": "/admin"
    },
    {
      "isPage": true,
      "path": "/error",
      "id": "_error",
      "component": () => import('../src/pages/error.svelte').then(m => m.default)
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/settings",
      "id": "_settings",
      "component": () => import('../src/pages/settings.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/staff/index",
          "id": "_staff_index",
          "component": () => import('../src/pages/staff/index.svelte').then(m => m.default)
        }
      ],
      "path": "/staff"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/store/:store",
          "id": "_store__store",
          "component": () => import('../src/pages/store/[store].svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/store/index",
          "id": "_store_index",
          "component": () => import('../src/pages/store/index.svelte').then(m => m.default)
        }
      ],
      "path": "/store"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/student/index",
          "id": "_student_index",
          "component": () => import('../src/pages/student/index.svelte').then(m => m.default)
        }
      ],
      "path": "/student"
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

